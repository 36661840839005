import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/bootstrap.min.css'
import './assets/css/cf.errors.css'
import './assets/css/css.css'
import './assets/css/font-awesome.min.css'
import './assets/css/slick.css'
import './assets/css/style.css'
import en from 'element-plus/es/locale/lang/en'

const app = createApp(App)

app.use(ElementPlus, {
    locale: en,
})


// 用于设置页面的title
router.beforeEach((to, from, next) => {

    /* 路由发生变化修改页面meta */
    console.log(to.meta.content)
    if(to.meta.content){
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }

    console.log("title:",to.meta.title);
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

app.use(router)



app.mount('#app')
