import { createRouter, createWebHistory } from 'vue-router'


// 设置title和description: https://blog.csdn.net/who_become_gods/article/details/113354995

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/IndexView.vue'),
    meta:{
      title: 'home page | value your time'
    }
  },
  {
    // 索引页，包含header和footer
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/IndexView.vue'),
    // apiKey 获取页
    children:[
      {
        path: '/shorturl/create',
        name: 'shorturl-create',
        component: () => import(/* webpackChunkName: "qrcode" */ '../views/ShortUrlView.vue'),
        meta:{
          title: 'generate short url | value your time',
          content:{
            keywords:'shorten link,short url,url shortener,short link free,short url generator,shorturl at,short link service',
            description:'generate short link free',
          },
        }
      },{
      path: '/qrcode/apikey',
      name: 'qrcode-apikey',
      component: () => import(/* webpackChunkName: "qrcode" */ '../views/ApiKeyView.vue'),
      meta:{
        title: 'get api key for qr code | value your time',
        content:{
          keywords:'qr code generator,qr code,api key for qr code',
          description:'get api key for qr code',
        },
      }
    },{
      // 首页
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "qrcode" */ '../views/HomeView.vue'),
      meta:{
        title: 'home | value your time',
        content:{
          keywords:'qr code,short url',
          description:'value your time,generate qr code,generate short url',
        },
      }
    },{
      // 二维码价格页，同时可以充值
      path: '/qrcode/pricing',
      name: 'qrcode-pricing',
      component: () => import(/* webpackChunkName: "qrcodeprcing" */ '../views/QrCodePriceView.vue'),
      meta:{
        title: 'pricing of qr code | value your time',
        content:{
          keywords:'qr code generator,qr code,api key for qr code',
          description:'qr code pricing',
        },
      }
    },{
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
      meta:{
        title: 'contact us | value your time',
        content:{
          keywords:'shorten link,short url,url shortener,short link free,short url generator,shorturl at,short link service,qr code',
          description:'contact us about short url and qr code',
        },
      }
    },{
      // 支付结果查询页
      path: '/pay/result',
      name: 'pay-result',
      component: () => import(/* webpackChunkName: "payresult" */ '../views/PayPalPayResultView.vue'),
      meta:{
        title: 'pay result | value your time',
        content:{
          keywords:'shorten link,short url,url shortener,short link free,short url generator,shorturl at,short link service,qr code',
          description:'query pay result ',
        },
      }
    },{
      path: '/faq',
      name: 'faq',
      component: () => import(/* webpackChunkName: "faq" */ '../views/FAQView.vue'),
      meta:{
        title: 'Frequently Asked Questions | value your time',
        content:{
          keywords:'shorten link,short url,url shortener,short link free,short url generator,shorturl at,short link service,qr code',
          description:'Frequently Asked Questions for vyourtime',
        },
      }
    },
      {
        path: '/privacy/policy',
        name: 'Privacy Policy',
        component: () => import(/* webpackChunkName: "faq" */ '../views/PrivacyPolicyView.vue'),
        meta:{
          title: 'Privacy Policy | value your time',
          content:{
            keywords:'shorten link,short url,url shortener,short link free,short url generator,shorturl at,short link service,qr code',
            description:'Privacy Policy for vyourtime',
          },
        }
      },
      {
        path: '/disclaimer',
        name: 'Disclaimer',
        component: () => import(/* webpackChunkName: "faq" */ '../views/DisclaimerView.vue'),
        meta:{
          title: 'Disclaimer | value your time',
          content:{
            keywords:'shorten link,short url,url shortener,short link free,short url generator,shorturl at,short link service,qr code',
            description:'Disclaimer for vyourtime',
          },
        }
      },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      meta:{
        title: 'about us | value your time',
        content:{
          keywords:'shorten link,short url,url shortener,short link free,short url generator,shorturl at,short link service,qr code',
          description:'about us for vyourtime',
        },
      }
    }
    ]

  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: '/',
  routes,
  // 防止 在A页面的某个位置跳转B页面后，还停留在A页面的位置
  scrollBehavior: ()=>{{y:0}}
})

export default router
